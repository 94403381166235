const API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;

const cache = new Map();

async function fetchAPI(query, { variables } = {}) {
  const headers = { 'Content-Type': 'application/json' };

  if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
    headers['Authorization'] = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`;
  }
  const res = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error('Failed to fetch API');
  }
  return json.data;
}

export async function getLatestStories() {
  if (cache.has('LatestStoryQuery')) {
    // console.log('Retrieve cached LatestStoryQuery');
    const cacheData = cache.get('LatestStoryQuery');
    return cacheData?.posts;
  }

  const data = await fetchAPI(
    `
    query LatestStoryQuery {
      posts(first: 3, where: {postSettingVisible: true}) {
        nodes {
          id
          title
          slug
          excerpt
          tags {
            nodes {
              id
              name
              slug
            }
          }
          date
          featuredImage {
            node {
              sourceUrl
              srcSet
              sizes
              mediaDetails {
                height
                width
              }
            }
          }
          author {
            node {
              name
            }
          }

        }
      }
    }
    `
  );

  cache.set('LatestStoryQuery', data);

  return data?.posts;
}

export async function getCategories() {
  const data = await fetchAPI(
    `
    query CategoryQuery {
      categories {
        nodes {
          id
          name
          slug
          uri
        }
      }
    }
  `
  );
  return data?.categories;
}

export async function getTags() {
  const data = await fetchAPI(
    `
    query TagQuery {
      tags {
        nodes {
          id
          name
          slug
          uri
        }
      }
    }
  `
  );
  return data?.tags;
}

export async function getBoltPost(slug) {
  const data = await fetchAPI(
    `
    query BoltPostQuery($slug: ID!) {
      boltPost(id: $slug, idType: SLUG) {
        id
        title
        slug
        content
        excerpt
        date
        featuredImage {
          node {
            uri
            mediaItemUrl
            sourceUrl
            srcSet
            sizes
            mediaDetails {
              height
              width
            }
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        date
        modified
        authorId
        seo {
          canonical
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphPublishedTime
          opengraphModifiedTime
          opengraphPublisher
          opengraphImage {
            sourceUrl
          }
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          twitterImage {
            sourceUrl
          }
        }
      }
    }
  `,
    {
      variables: { slug: slug },
    }
  );
  return data?.boltPost;
}

export async function getPost(slug) {
  const data = await fetchAPI(
    `
    query PostQuery($slug: ID!) {
      post(id: $slug, idType: SLUG) {
        id
        title
        slug
        content
        excerpt
        featuredImage {
          node {
            uri
            mediaItemUrl
            sourceUrl
            srcSet
            sizes
            mediaDetails {
              height
              width
            }
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        date
        modified
        settings {
          hidden
        }
        authorId
        blogPostMeta {
          relatedArticle3
          relatedArticle2
          relatedArticle1
          authorUuid
        }
        faqSettings {
          title
          faq {
            answer
            question
          }
        }
        seo {
          canonical
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphPublishedTime
          opengraphModifiedTime
          opengraphPublisher
          opengraphImage {
            sourceUrl
          }
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          twitterImage {
            sourceUrl
          }
        }
      }

      posts(first: 3, where: {postSettingVisible: true}) {
        nodes {
          id
          title
          slug
          excerpt
          tags {
            nodes {
              id
              name
              slug
            }
          }
          date
          featuredImage {
            node {
              sourceUrl
              srcSet
              sizes
              mediaDetails {
                height
                width
              }
            }
          }
          author {
            node {
              name
            }
          }

        }
      }
    }

  `,
    {
      variables: { slug: slug },
    }
  );
  return data;
}

export async function getStoryBySlug(slug) {
  const data = await fetchAPI(
    `
    query SlugPostQuery($slug: ID!) {
      post(id: $slug, idType: SLUG) {
        id
        title
        slug
        excerpt
        tags {
          nodes {
            id
            name
            slug
          }
        }
        date
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
  `,
    {
      variables: { slug: slug },
    }
  );
  return data?.post;
}

export async function getReferral(slug) {
  const data = await fetchAPI(
    `
    query ReferralQuery($slug: ID!) {
      referral(id: $slug, idType: SLUG) {
          id
          slug
          title
          content
          
          referralSettings {
            type
            requestDetails
          }
          referralDetails {
            offerEndnotes
            offerDetails
          }
          date
      }
    }
  `,
    {
      variables: { slug: slug },
    }
  );
  return data?.referral;
}

export async function getPromotion(slug) {
  const data = await fetchAPI(
    `
    query PromotionQuery($slug: ID!) {
      promotion(id: $slug, idType: SLUG) {
          id
          slug
          title
          content
          promotionDetails {
            faq
            details
          }
          date
      }
    }
  `,
    {
      variables: { slug: slug },
    }
  );
  return data?.promotion;
}

export async function getCaseStudy(slug) {
  const data = await fetchAPI(
    `
    query CaseStudyQuery($slug: ID!) {
      caseStudy(id: $slug, idType: SLUG) {
        id
        title
        slug
        content
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
          }
        }
        date
      }
    }
  `,
    {
      variables: { slug: slug },
    }
  );
  return data?.caseStudy;
}
export async function getCaseStudies(cursor) {
  const data = await fetchAPI(
    `
    query CaseStudyListQuery($cursor: String!) {
      caseStudies(after: $cursor, first:10) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            slug
            excerpt
            featuredImage {
              node {
                sourceUrl
                srcSet
                sizes
              }
            }
            date
          }
        }
      }
    }
  `,
    {
      variables: { cursor: cursor || '' },
    }
  );
  return data?.caseStudies;
}

export async function getJd(slug) {
  const data = await fetchAPI(
    `
    query CareerQuery($slug: ID!) {
      jd(id: $slug, idType: SLUG) {
        id
        title
        slug
        content
        menuOrder
        excerpt
        categories {
          nodes {
            id
            name
          }
        }
        date
      }
    }
  `,
    {
      variables: { slug: slug },
    }
  );
  return data?.jd;
}

export async function getCareers(cursor) {
  const data = await fetchAPI(
    `
    query CareerListQuery($cursor: String!) {
      jds(after: $cursor, first:10) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            slug
            menuOrder
            excerpt
            categories {
              nodes {
                id
                name
              }
            }
            date
          }
        }
      }
    }
  `,
    {
      variables: { cursor: cursor || '' },
    }
  );
  return data?.jds;
}

export async function getStories(cursor) {
  const data = await fetchAPI(
    `
    query PostListQuery($cursor: String!) {
      posts(after: $cursor, first:10, where: {postSettingVisible: true}) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            slug
            date
            excerpt
            tags {
              nodes {
                id
                name
                slug
              }
            }
            author {
              node {
                name
              }
            }
            featuredImage {
              node {
                sourceUrl
                srcSet
                sizes
                mediaDetails {
                  sizes {
                    sourceUrl
                    height
                    width
                    file
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: { cursor: cursor || '' },
    }
  );
  return data?.posts;
}

export async function getAllStorySlugs() {
  const data = await fetchAPI(
    `
    query PostSlugsQuery {
      posts(first: 1000) {
        nodes {
          slug
        }
      }
    }
  `
  );
  return data?.posts?.nodes;
}

export async function getVisibleStorySlugs() {
  const data = await fetchAPI(
    `
    query PostSlugsQuery {
      posts(first: 1000, where: {postSettingVisible: true}) {
        nodes {
          slug
        }
      }
    }
  `
  );
  return data?.posts?.nodes;
}

export async function getStoriesBySlug(slugs) {
  const slugStr = slugs.join(', ');

  if (cache.has(`SlugPostsQuery-${slugStr}`)) {
    // console.log(`Retrieve cached SlugPostsQuery-${slugStr}`);
    const cacheData = await cache.get(`SlugPostsQuery-${slugStr}`);
    return cacheData?.posts?.nodes;
  }

  const data = await fetchAPI(
    `
    query SlugPostsQuery($slugs: String!) {
      posts(where: {slugIn: $slugs}) {
        nodes {
          id
          title
          slug
          excerpt
          tags {
            nodes {
              id
              name
              slug
            }
          }
          blogPostMeta {
            authorUuid
          }
          featuredImage {
            node {
              sourceUrl
              srcSet
              sizes
            }
          }
          date
          author {
            node {
              name
            }
          }
        }
      }
    }
  `,
    {
      variables: { slugs: slugStr },
    }
  );
  // console.log(`Setting cached SlugPostsQuery-${slugStr}`);
  cache.set(`SlugPostsQuery-${slugStr}`, data);
  // console.log(data);
  return data?.posts?.nodes;
}

export async function getLatestPostsByTag(tagName) {
  const data = await fetchAPI(
    `
    query LatestTagPostsQuery($tagName : String!)  {
      posts(first: 4,  where: { tag: $tagName } ) {
        nodes {
          id
          title
          content
          excerpt
          slug
          date
          featuredImage {
            node {
              id
              sourceUrl
              srcSet
              sizes
            }
          }
          tags {
            nodes {
              id
              name
              slug
            }
          }
          categories {
           nodes {
              id
              name
              slug
            }
          }
          author {
            node {
              name
            }
          }
        }
      }
    }
  `,
    {
      variables: { tagName },
    }
  );
  return data?.posts;
}

export async function getStoriesByCategory(cursor, categoryName) {
  const data = await fetchAPI(
    `
    query PostListByCategoryQuery($cursor: String!, $categoryName : String!) {
      posts(after: $cursor, first: 10, where: {postSettingVisible: true, categoryName: $categoryName}) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            title
            slug
            date
            excerpt
            tags {
              nodes {
                id
                name
                slug
              }
            }
            featuredImage {
              node {
                sourceUrl
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: { cursor: cursor || '', categoryName: categoryName },
    }
  );
  return data?.posts;
}

/////////////////////////////////////////////////////////////////

// export async function getPreviewPost(id, idType = 'DATABASE_ID') {
//   const data = await fetchAPI(
//     `
//     query PreviewPost($id: ID!, $idType: PostIdType!) {
//       post(id: $id, idType: $idType) {
//         databaseId
//         slug
//         status
//       }
//     }`,
//     {
//       variables: { id, idType },
//     }
//   );
//   return data.post;
// }

export async function getAllJsWithSlug() {
  const data = await fetchAPI(`
    {
      jds(first: 10000) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `);
  return data?.jds;
}

export async function getAllPostsWithSlug() {
  const data = await fetchAPI(`
    {
      posts(first: 10000) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `);
  return data?.posts;
}
